// ------ ENVIRONMENT VARIABLES -----
export const APP_ID = {
  dev: 'patient-mobile-dev-bgjws',
  prod: 'prod-drmns',
};

export const PUSHER = {
  dev: {
    app: '9e90daeff2435a856dbf',
    cluster: 'us2',
  },
  prod: {
    app: 'd9a9544f9ac021dcd9c3',
    cluster: 'us2',
  },
};

export const PHOTO_URL =
  'https://f002.backblazeb2.com/file/bm-public/headshots/';

export const CDN_URL = {
  dev: 'https://pcdn-dev.bettermedics.org/',
  prod: 'https://pcdn.bettermedics.org/',
};

export const AMPLITUDE = {
  dev: '649ef82eae0f38eae90ce10a85ae1214',
  prod: 'faa481967c91dc8d5c95e8dde10d571e',
};

export const BRAVADO_URL = {
  dev: 'https://ayva-demo.dev.bravadocloud.com',
  prod: 'https://ayva.bravadohealth.com',
};

export const BRAVADO_AUTH_URL = {
  dev: 'https://ayva-demo-services.dev.bravadocloud.com',
  prod: 'https://ayva-services.bravadohealth.com',
};

export const BRAVADO_KEY = {
  dev: '95DB96CC80359B67D715',
  prod: 'B9AF6D227E679A3E069E',
};

export const DOCUMENT_TITLE = 'BetterMedics';

// ----- APP SETTINGS ----

export const TIMEOUT = {
  time: 6000,
  warnFor: 60,
};

export const VERBOSE = false;

// ------ DUMMY DATA -----

export const PATIENTS = [
  {
    mrn: '876543',
    name: {
      given: 'Frank',
      family: 'Penny',
    },
    address: {
      street1: '123 Juniper Rd',
      street2: 'Apt 2B',
      city: 'Cleveland',
      state: 'OH',
      zip: '44106',
      directions:
        'Enter from street. Go up to 2nd floor. Last door on the right.',
    },
    coordinates: {
      lat: 41.5118581,
      lng: -81.6073875,
    },
    phone: '(216) 303-4694',
    age: 79,
    gender: 'M',
    height: '5\'8"',
    weight: '215',
    diagnosis: [
      {
        code: 'T2D',
        label: 'Type 2 Diabetes',
        date: null,
      },
      {
        code: 'HD',
        label: 'Heart Disease',
        date: null,
      },
    ],
    status: 'waiting',
    photo:
      'https://images.unsplash.com/photo-1520799275532-15f68640b66b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
    rep: null,
    startDate: null,
    recording: null,
  },
  {
    mrn: '12345',
    name: {
      given: 'Dorthy',
      family: 'Johnson',
    },
    address: {
      street1: '100 Alfred Lerner Rd.',
      city: 'Cleveland',
      state: 'OH',
      zip: '44114',
      directions: 'Drive to the lake, look for the factory of sadness.',
    },
    coordinates: {
      lat: 41.5118581,
      lng: -81.6073875,
    },
    phone: '(216) 303-4694',
    age: 72,
    gender: 'F',
    height: '5\'8"',
    weight: '215',
    diagnosis: [
      {
        code: 'T2D',
        label: 'Type 2 Diabetes',
        date: null,
      },
      {
        code: 'HD',
        label: 'Heart Disease',
        date: null,
      },
    ],
    status: 'careline',
    photo:
      'https://images.unsplash.com/photo-1577368287217-16ff9373a733?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    rep: {
      firstName: 'Carol',
      lastName: 'Henderson',
      photo:
        'https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    },
    startDate: null,
    recording: null,
  },
];

export const PROVIDERS = {
  ems: [
    {
      id: '525hjsd',
      agency: 'Bay Village',
      primaryDispatch: 'Westcom Fire/EMS',
      dispatchNumber: '(440) 892-2900',
      otherNumber: '(440) 892-2900',
    },
    {
      id: '75hdf85h',
      agency: 'Beachwood',
      primaryDispatch: 'Beachwood Police/Fire',
      dispatchNumber: '(216) 464-1234',
      otherNumber: '',
    },
  ],
  mco: [
    {
      id: '567890',
      name: 'Buckeye Health',
      type: 'MCO',
      address: {
        street1: '125 W. 25th St',
        city: 'Cleveland',
        state: 'OH',
        zip: '44114',
      },
      phone: '(866) 246-4358',
    },
    {
      id: '663229',
      name: 'CareSource Medicaid',
      type: 'MCO',
      address: {
        street1: 'P.O. Box 8738',
        city: 'Dayton',
        state: 'OH',
        zip: '45401-873814',
      },
      phone: '(800) 488-0134',
    },
  ],
  pharmacy: [
    {
      id: '123456',
      name: 'CVS Store #15672',
      type: 'Pharmacy',
      address: {
        street1: '789 Elmore Ave.',
        city: 'Cleveland',
        state: 'OH',
        zip: '44120',
      },
      phone: '(216) 624-5555',
    },
    {
      id: '876543',
      name: 'CVS Store #23764',
      type: 'Pharmacy',
      address: {
        street1: '52 Lilly Lane',
        city: 'Cleveland',
        state: 'OH',
        zip: '44113',
      },
      phone: '(216) 855-9221',
    },
    {
      id: '3487546',
      name: 'CVS Store #8990',
      type: 'Pharmacy',
      address: {
        street1: '2501 Sycamore St.',
        city: 'Cleveland',
        state: 'OH',
        zip: '44231',
      },
      phone: '(216) 492-0160',
    },
  ],
};

export const TEAM = {
  admin: [
    {
      id: '97897',
      firstName: 'Chris',
      lastName: 'Nickless',
      fullName: 'Chris Nickless',
      email: 'chris.nickless@bettermedics.org',
    },
    {
      id: '564743',
      firstName: 'Matt',
      lastName: 'Tew',
      fullName: 'Matt Tew',
      email: 'matt.tew@bettermedics.org',
    },
    {
      id: '554433',
      firstName: 'Shannon',
      lastName: 'Lyons',
      fullName: 'Shannon Lyons',
      email: 'shannon.lyons@bettermedics.org',
    },
    {
      id: '876543',
      firstName: 'Michelle',
      lastName: 'Troutman',
      fullName: 'Michelle Troutman',
      email: 'michelle.troutman@bettermedics.org',
    },
    {
      id: '667788',
      firstName: 'Ian',
      lastName: 'Stanich',
      fullName: 'Ian Stanich',
      email: 'ian.stanich@bettermedics.org',
    },
  ],
  physicians: [],
  careline: [
    {
      id: '557788',
      firstName: 'Courtney',
      lastName: 'Stanich',
      email: 'cstanich@horizonhealthservicesllc.com',
    },
    {
      id: '667788',
      firstName: 'Ian',
      lastName: 'Stanich',
      email: 'istanich@bettermedics.org',
    },
    {
      id: '478515',
      firstName: 'Meghan',
      lastName: 'Skarbinski',
      email: 'mskarbinski@horizonhealthservicesllc.com',
    },
    {
      id: '6985558',
      firstName: 'Nicole',
      lastName: 'Kerkian',
      email: 'nkerkian@horizonhealthservicesllc.com',
    },
  ],
  hha: [],
};

export const TEST_VITALS = {
  temperature: {
    temp: 100.6,
    timestamp: Date.now(),
    mode: 'adult',
  },
  pulseox: {
    battery: 3,
    pi: 7.2,
    pulse: 84,
    spo2: 99,
    pleth: [
      { value: 100 },
      { value: 93 },
      { value: 85 },
      { value: 206 },
      { value: 71 },
      { value: 65 },
      { value: 61 },
      { value: 59 },
      { value: 59 },
      { value: 60 },
      { value: 62 },
      { value: 63 },
      { value: 64 },
      { value: 63 },
      { value: 61 },
      { value: 58 },
      { value: 55 },
      { value: 51 },
      { value: 48 },
      { value: 44 },
      { value: 41 },
      { value: 39 },
      { value: 37 },
      { value: 35 },
      { value: 34 },
      { value: 34 },
      { value: 33 },
      { value: 32 },
      { value: 32 },
      { value: 31 },
      { value: 31 },
      { value: 31 },
      { value: 30 },
      { value: 30 },
      { value: 29 },
      { value: 100 },
      { value: 93 },
      { value: 85 },
      { value: 206 },
      { value: 71 },
      { value: 65 },
      { value: 61 },
      { value: 59 },
      { value: 59 },
      { value: 60 },
      { value: 62 },
      { value: 63 },
      { value: 64 },
      { value: 63 },
      { value: 61 },
      { value: 58 },
      { value: 55 },
      { value: 51 },
      { value: 48 },
      { value: 44 },
      { value: 41 },
      { value: 39 },
      { value: 37 },
      { value: 35 },
      { value: 34 },
      { value: 34 },
      { value: 33 },
      { value: 32 },
      { value: 32 },
      { value: 31 },
      { value: 31 },
      { value: 31 },
      { value: 30 },
      { value: 30 },
      { value: 29 },
      { value: 100 },
      { value: 93 },
      { value: 85 },
      { value: 206 },
      { value: 71 },
      { value: 65 },
      { value: 61 },
      { value: 59 },
      { value: 59 },
      { value: 60 },
      { value: 62 },
      { value: 63 },
      { value: 64 },
      { value: 63 },
      { value: 61 },
      { value: 58 },
      { value: 55 },
      { value: 51 },
      { value: 48 },
      { value: 44 },
      { value: 41 },
      { value: 39 },
      { value: 37 },
      { value: 35 },
      { value: 34 },
      { value: 34 },
      { value: 33 },
      { value: 32 },
      { value: 32 },
      { value: 31 },
      { value: 31 },
      { value: 31 },
      { value: 30 },
      { value: 30 },
      { value: 29 },
    ],
  },
  bp: {
    battery: 29,
    dia: 92,
    sys: 116,
    hr: 79,
    timestamp: Date.now(),
  },
};

export const HISTORY = [
  {
    id: '1023456789',
    visitDate: {
      startDate: '2020-10-21T16:24:54+00:00',
      endDate: '2020-10-27T16:24:54+00:00',
    },
    type: 'Home',
    diagnosis: ['Chest Pain', 'Dizziness'],
    notes: 'Patient had a routine checkup',
  },
];

// ------ FORM FIELD PRESETS ------

export const STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const MONTHS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const DISCONNECT_OPTIONS = [
  {
    id: 'noAnswer',
    label: 'No Answer on Callback',
  },
  {
    id: 'accidental',
    label: 'Accident',
  },
  {
    id: 'tabletMalfunction',
    label: 'Tablet Malfunction',
  },
  {
    id: 'other',
    label: 'Other',
  },
];

export const MEDICATION_FREQUENCIES = {
  qd: { label: 'Daily' },
  bid: { label: 'Twice daily' },
  tid: { label: '3 times daily' },
  qam: { label: 'Every morning' },
  qhs: { label: 'At bedtime' },
  separator: { label: '──────────────────────────────', disabled: true },
  '1xonly': { label: '1 time only' },
  q12d: { label: '1 to 2 times daily' },
  '15minac': { label: '15 minutes before meals' },
  bid2: { label: '2 times daily' },
  q23d: { label: '2 to 3 times daily' },
  tidpc: { label: '3 times daily after meals' },
  tidp: { label: '3 times daily as needed' },
  tidac: { label: '3 times daily before meals' },
  tidw: { label: '3 times daily with food' },
  tidwm: { label: '3 times daily with meals' },
  t4dwm: { label: '3 times weekly 4 times daily 30 min before meals and bed' },
  qid: { label: '4 times daily' },
  qidp: { label: '4 times daily as needed' },
  qidachs: { label: '4 times daily before meals and at bedtime' },
  '4xwk': { label: '4 times weekly' },
  q46d: { label: '4 to 6 times daily' },
  '5xd': { label: '5 times daily' },
  'pc&hs': { label: 'After meals and at bedtime' },
  adbp: { label: 'As directed by physcian' },
  prn: { label: 'As needed' },
  qhsp: { label: 'At bedtime as needed' },
  achs: { label: 'Before meals and at bedtime' },
  qdp: { label: 'Daily as needed' },
  qdx5da: { label: 'Daily for 5 days' },
  qde: { label: 'Daily on empty stomach' },
  qdw: { label: 'Daily with food' },
  q12h: { label: 'Every 12 hours' },
  q12hp: { label: 'Every 12 hours as needed' },
  q12hx2doses: { label: 'Every 12 hours for 2 doses' },
  q12he: { label: 'Every 12 hours on empty stomach' },
  q12hw: { label: 'Every 12 hours with food' },
  q15minmrx5: { label: 'Every 15 min, may repeat 5 times' },
  gq2w: { label: 'Every 2 weeks' },
  q3d: { label: 'Every 3 days' },
  q3h: { label: 'Every 3 hours' },
  q3hwax7d: { label: 'Every 3 hours while awake (6 doses) for 7 days' },
  q36h: { label: 'Every 3 to 6 hours' },
  q36hp: { label: 'Every 3 to 6 hours as needed' },
  q30minp: { label: 'Every 30 minutes as needed' },
  q4h: { label: 'Every 4 hours' },
  q4hppc: { label: 'Every 4 hours after meals' },
  q4hp: { label: 'Every 4 hours as needed' },
  q4hwa: { label: 'Every 4 hours while awake' },
  'q4-6h': { label: 'Every 4 to 6 hours' },
  q46hp: { label: 'Every 4 to 6 hours as needed' },
  q6h: { label: 'Every 6 hours' },
  q6hp: { label: 'Every 6 hours as needed' },
  q6he: { label: 'Every 6 hours on empty stomach' },
  q6hw: { label: 'Every 6 hours with food' },
  q68h: { label: 'Every 6 to 8 hours' },
  q68hp: { label: 'Every 6 to 8 hours as needed' },
  q8h: { label: 'Every 8 hours' },
  q8hp: { label: 'Every 8 hours as needed' },
  q8he: { label: 'Every 8 hours on empty stomach' },
  q812h: { label: 'Every 8 to 12 hours' },
  q812hp: { label: 'Every 8 to 12 hours as needed' },
  qamp: { label: 'Every morning as needed' },
  qod: { label: 'Every other day' },
  qw: { label: 'Every week' },
  statrepeat: { label: 'Now and repeat 1 time' },
  now1q24h: { label: 'Now, then 1 every 24 hours' },
  statr2hp: { label: 'Now, may repeat in 2 hours as needed' },
  oncedaily: { label: 'Once daily' },
  qdhs: { label: 'Once in the evening' },
  q12dx23wqod: {
    label:
      'Once or twice daily for 2 to 3 weeks then every other day thereafter',
  },
  q12dx7d: { label: 'Once or twice daily for 7 days' },
  bidp: { label: 'Twice daily as needed' },
  bidw: { label: 'Twice daily with food' },
  bidwater: { label: 'Twice daily with water' },
  '2xw': { label: 'Twice weekly' },
  '2xwkx6m': { label: 'Twice weekly for 6 months' },
  d: { label: 'With dinner' },
  qms: { label: 'With each meal or snack' },
};

export const RACES = [
  'White',
  'Black',
  'American Indian',
  'Latino or Hispanic',
  'Asian',
  'Pacific Islander',
];

export const LANGUAGES = [
  'English',
  'Spanish',
  'Arabic',
  'Bengali',
  'Chinese',
  'Dutch',
  'French',
  'German',
  'Hindi',
  'Italian',
  'Japanese',
  'Korean',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Russian',
  'Swahili',
  'Swedish',
  'Turkish',
  'Welsh',
  'Yiddish',
];

export const CLINICAL_STUDY_GROUPS = [
  'ROUND 1: Horizon_1',
  'ROUND 2: Horizon_2',
  'ROUND 3: Generations_1',
  'ROUND 4: Horizon_3',
  'ROUND 5: One-to-Many',
];

export const CODE_STATUS_TYPES = {
  'Full Code': {
    title: 'Full Code',
    abbr: 'FC',
    color: '#57B3FA',
  },
  'DNR-CCA': {
    title: 'DNR Comfort Care-Arrest',
    abbr: 'DNR-CCA',
    color: '#FFAA76',
  },
  'DNR-CC': {
    title: 'DNR Comfort Care',
    abbr: 'DNR-CC',
    color: '#F05672',
  },
};
