import React from 'react';
import styled from 'styled-components';

const Cell = ({ id, item, options, value, active }) => {
  // quick fn to handle nested obj values (e.g. medications.display)
  let val =
    value ||
    (() => {
      const parts = id.split('.');
      return parts.reduce((current, next) => {
        return current[next];
      }, item);
    })();

  return (
    <Text
      style={{
        ...(options.flex && { flex: options.flex }),
        ...(options.width && { width: options.width }),
        ...(options.align === 'right' && { justifyContent: 'flex-end' }),
        ...(options.fontSize && { fontSize: options.fontSize }),
        ...(options.colorFn && { color: options.colorFn(val) }),
      }}
      active={active}>
      {val || <EmptyDash />}
    </Text>
  );
};

const TableItem = ({ item, config, onClick, sort, widget, ehr }) => (
  <Row
    mouseEnabled={onClick}
    onClick={() => onClick(item)}
    widget={widget}
    ehr={ehr}>
    {config &&
      config.map((cell, index) => (
        <Cell
          key={cell.id}
          id={cell.id}
          item={item}
          options={config[index]}
          value={cell.formatFn ? cell.formatFn(item[cell.id]) : null}
          active={sort === cell.id}
        />
      ))}
  </Row>
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 15px;
  margin-bottom: ${props => (props.widget ? 0 : '10px')};
  cursor: pointer;

  pointer-events: ${props => (props.mouseEnabled ? 'all' : 'none')};

  ${({ theme, widget }) =>
    widget &&
    `
    border: none;
    border-bottom: 1px solid ${theme.colors.border};
    border-radius: 0;
    padding: 15px 3px;

    &:last-child {
      border-bottom: none;
    }

    ${Text} {
      font-size: 15px;
    }
  `}

  background-color: ${props => (props.ehr ? 'white' : 'transparent')};

  &:hover {
    background-color: ${props => props.theme.colors.tableHover};
  }
`;

const Text = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding-right: 10px;
  cursor: pointer;

  font-family: ${props => props.theme.fonts.secondary};
  font-weight: ${props =>
    props.active
      ? props.theme.fontWeights.medium
      : props.theme.fontWeights.regular};
  font-size: 16px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 0;
  line-height: 28px;
  pointer-events: none;

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: 1199px) {
    width: 90px;
  }
`;

const EmptyDash = styled.span`
  display: block;
  width: 15px;
  height: 4px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 10px;
`;

export default TableItem;
