import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { darken, lighten } from 'polished';
import { formatDistanceToNow, parseISO } from 'date-fns';
import {
  FaPhoneAlt,
  FaPhoneSlash,
  FaTimes,
  FaLaptopMedical,
} from 'react-icons/fa';
import { getAge } from '../../hooks/useTextHelper';
import UserIcon from '../common/UserIcon';
import WithTooltip from '../common/WithTooltip';

const getTooltipTitle = status => {
  return status === 'disconnected'
    ? 'Disconnected'
    : status === 'new'
    ? 'Incoming Call'
    : status === 'patientCancelled'
    ? 'Patient Hung Up'
    : 'Active Call';
};

const WaitingRoomCall = ({ call, onMissedCall }) => {
  const { date, patient, room, status } = call;

  const history = useHistory();

  const openCall = () => {
    if (status === 'disconnected' || status === 'patientCancelled') {
      onMissedCall(call);
    } else history.push(`/video/${room}`);
  };

  return (
    <Wrapper calltype={call.callType} onClick={openCall}>
      <PhotoWrapper>
        <UserIcon user={patient} size="80px" patient />
      </PhotoWrapper>
      <Patient>
        <PatientName>{`${patient.name.given} ${patient.name.family}`}</PatientName>
        <PatientAge>{`Age: ${getAge(patient.birthDate)}`}</PatientAge>
        {patient.telecom && patient.telecom.home ? (
          <PatientPhone>{patient.telecom.home}</PatientPhone>
        ) : null}
      </Patient>
      <Status>
        <WithTooltip title={getTooltipTitle(status)}>
          <StatusOrb status={status}>
            {status === 'disconnected' ? (
              <FaTimes />
            ) : status === 'new' ? (
              <FaPhoneAlt />
            ) : status === 'patientCancelled' ? (
              <FaPhoneSlash />
            ) : (
              <FaLaptopMedical />
            )}
          </StatusOrb>
        </WithTooltip>
      </Status>
      <Timer>
        <TimeText>
          {formatDistanceToNow(parseISO(date), { addSuffix: true })}
        </TimeText>
      </Timer>
      <Cell>
        <CallType>{call.callType}</CallType>
      </Cell>
    </Wrapper>
  );
};

const ringing = keyframes`
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
`;

const PhotoWrapper = styled.div`
  position: relative;
`;

const Patient = styled.div`
  flex: 1;
  padding: 0 20px;
`;

const PatientName = styled.p`
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.textColors.primary};
  margin-bottom: 8px;
`;

const PatientSubtitle = styled.p`
  display: inline-block;
  font-size: 15px;
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.textColors.secondary};
  margin: 0 0 8px;
`;

const PatientAge = styled(PatientSubtitle)``;
const PatientPhone = styled(PatientSubtitle)`
  margin-left: 25px;
`;

const Cell = styled.div`
  position: relative;
  text-align: center;
  padding: 10px 20px;
  width: 180px;

  @media (max-width: 1199px) {
    width: 90px;
  }
`;

const StatusOrb = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${props =>
    props.status === 'new'
      ? props.theme.colors.green
      : props.status === 'disconnected'
      ? props.theme.colors.warning
      : props.status === 'patientCancelled'
      ? props.theme.colors.tertiary
      : props.theme.colors.blue};
  animation-name: ${props => (props.status === 'new' ? ringing : 'none')};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  svg {
    color: white;
    font-size: 23px;
  }

  &:hover {
    background-color: ${props =>
      props.status === 'new'
        ? darken(0.05, props.theme.colors.green)
        : props.status === 'disconnected'
        ? darken(0.03, props.theme.colors.warning)
        : props.status === 'patientCancelled'
        ? darken(0.05, props.theme.colors.tertiary)
        : darken(0.05, props.theme.colors.blue)};
  }
`;

const Status = styled(Cell)`
  height: 100%;
  text-align: center;
`;

const Timer = styled(Cell)``;

const TimeText = styled.p`
  margin: 0;
  color: ${props => props.theme.textColors.primary};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const CallType = styled.p`
  margin: 0;
  color: ${props => props.theme.textColors.primary};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 15px 20px;
  margin: 10px 0;
  border-radius: 20px;
  background-color: ${props =>
    props.calltype === 'acute'
      ? lighten(0.29, props.theme.colors.warning)
      : 'transparent'};
  border: 1px solid;
  border-color: ${props =>
    props.calltype === 'acute'
      ? lighten(0.21, props.theme.colors.warning)
      : 'transparent'};

  ${({ calltype }) =>
    calltype !== 'acute' &&
    `
    &:hover {
      box-shadow: 0 0 10px #f5f7ff;
      border-color: #f5f7ff;
      background-color: white;
    }
  `}

  ${({ calltype, theme }) =>
    calltype === 'acute' &&
    `
    &:hover {
      box-shadow: 0 0 10px ${lighten(0.29, theme.colors.warning)};
    }

    ${CallType} {
      color: ${theme.colors.warning};
      font-weight: ${theme.fontWeights.bold};
      text-transform: uppercase;
    }

    ${TimeText} {
      color: ${theme.colors.warning};
    }

    ${PatientSubtitle} {
      color: ${theme.textColors.primary};
    }
  `}
`;

export default WaitingRoomCall;
